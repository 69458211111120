<template>
  <div>
    <b-row class="mb-2" cols="1" cols-sm="2">
      <b-col align-self="center">
        <h3 class="mb-2 mb-md-0">สรุปรายได้ประจำเดือน</h3>
      </b-col>
      <b-col>
        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end">
          <p class="m-0 mb-50">เลือกเดือน-ปี</p>
          <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
            <v-select
              v-model="cDate"
              :options="dateSelectOptions"
              :clearable="false"
              :disabled="pageLoading"
              :get-option-label="option => $date(option).format('MM-YYYY')"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-overlay :show="itemsLoading">
      <!-- <table-data-widget
        ref="refTablePartnerSaleInfo"
        title="ยอดขาย"
        :columns="cColumns"
        :items="items"
        :new-able="false"
        :view-able="false"
        :edit-able="false"
        :delete-able="false"
      /> -->
      <table-local-v-1-widget ref="refTablePartnerSaleInfo" title="ยอดขาย" :columns="cColumns" :items="items" />
    </b-overlay>

    <b-overlay :show="itemsRefundLoading">
      <!-- <table-data-widget
        ref="refTablePartnerSaleRefundInfo"
        title="ยอดลด (รีฟัน)"
        :columns="cColumnsRefund"
        :items="itemsRefund"
        :new-able="false"
        :view-able="false"
        :edit-able="false"
        :delete-able="false"
      /> -->
      <table-local-v-1-widget
        ref="refTablePartnerSaleRefundInfo"
        title="ยอดลด (รีฟัน)"
        :columns="cColumnsRefund"
        :items="itemsRefund"
      />
    </b-overlay>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: { vSelect },
  props: {
    userId: {
      type: String,
      default: '',
    },
    dateSelectOptions: {
      type: Array,
      default: () => [],
    },
    dateSelected: {
      type: String,
      default: null,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    roleView: {
      type: String,
      default: 'partner',
    },
  },
  data() {
    return {
      items: [],
      itemsLoading: false,
      itemsRefund: [],
      itemsRefundLoading: false,
    }
  },
  computed: {
    cDate: {
      get() {
        return this.dateSelected
      },
      set(val) {
        this.$emit('changeDateSelect', val)
      },
    },
    cColumns() {
      const columns = [
        {
          label: 'วันที่ (วัน-เดือน-ปี)',
          field: row => this.$date(row.created_at).format('D-MM-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ชื่อรูปรอง',
          field: 'title',
        },
        {
          label: 'ราคารูป',
          field: 'image_price',
          type: 'currency',
          tdClass: 'text-right text-warning',
          thClass: 'text-right',
        },
        {
          label: 'อัตราค่าธรรมเนียม',
          field: 'percent_revenue_discount',
          formatFn: val => `${Math.round(val)}%`,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'ค่าธรรมเนียม',
          field: 'fee',
          type: 'currency',
          thClass: 'text-right',
          tdClass: 'text-right text-danger',
        },
        {
          label: 'อัตราค่าคอมมิชชั่น Affiliate',
          field: row => {
            if (row.commission_status) {
              return `${Number(row.commission_rate)}%`
            }

            return '-'
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'ค่าคอมมิชชั่น Affiliate',
          field: 'af_revenue',
          formatFn: val => `-${this.gFormatNumberToCurrency(val)}`,
          tdClass: 'text-right text-danger',
          thClass: 'text-right',
        },
        {
          label: 'เครดิตที่ได้รับ',
          field: 'revenue',
          type: 'currency',
          thClass: 'text-right',
          tdClass: 'text-right text-success',
        },
        // {
        //   label: 'เครดิตคงเหลือ',
        //   field: 'credit_balance',
        //   type: 'currency',
        //   tdClass: 'text-right text-success',
        // },
        // {
        //   label: 'เลขอ้างอิง',
        //   field: 'id',
        //   width: '200px',
        // },
      ]

      return columns
    },
    cItems() {
      return []
    },
    cColumnsRefund() {
      return [
        {
          label: 'วันที่',
          field: row => this.$date(row.created_at).format('MM-DD-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ชื่อผู้ขอคืนเงิน',
          field: 'user_name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ยอดคงเหลือ (ก่อนคืนเงิน)',
          field: 'credit_balance',
          type: 'currency',
          tdClass: 'text-right text-success',
          thClass: 'text-right',
        },
        {
          label: 'ยอดคืนเงิน',
          field: 'credit_amount',
          type: 'currency',
          tdClass: 'text-right text-danger',
          thClass: 'text-right',
        },
        {
          label: 'ยอดคงเหลือ (หลังคืนเงิน)',
          field: 'credit_total',
          type: 'currency',
          tdClass: 'text-right text-warning',
          thClass: 'text-right',
        },
        // {
        //   label: 'เลขอ้างอิง',
        //   field: 'id',
        // },
        // {
        //   label: 'ชื่อรูป',
        //   field: 'title',
        // },
        // {
        //   label: 'เครดิต ลด',
        //   field: 'credit_amount',
        //   type: 'number',
        //   tdClass: 'text-right text-warning',
        // },
        // {
        //   label: 'เครดิต คงเหลือ',
        //   field: 'partner_credit_total',
        //   type: 'number',
        //   tdClass: 'text-right text-success',
        // },
      ]
    },
  },
  watch: {
    // userId(newVal) {
    //   console.log('newVal', newVal)
    // },
    dateSelected(newVal) {
      this.getPartnerSalesDetails(this.userId, newVal)
      this.getPartnerSalesRefund(this.userId, newVal)
    },
  },
  async created() {
    this.getPartnerSalesDetails(this.userId, this.dateSelected)
    this.getPartnerSalesRefund(this.userId, this.dateSelected)
  },
  methods: {
    async getPartnerSalesDetails(id, date) {
      if (!id || !date) return
      // this.$emit('updatePageLoading', true)
      this.itemsLoading = true
      const resp = await this.api.get(`api/partner/dashboard/table-revenue/${id}/${date}`).catch(() => null)
      // console.log('getPartnerSalesDetails', resp)
      if (resp) {
        this.items = [...resp]
      }
      this.itemsLoading = false
      // this.$emit('updatePageLoading', false)
    },
    async getPartnerSalesRefund(id, date) {
      if (!id || !date) return
      this.itemsRefundLoading = true
      const resp = await this.api
        .get(`api/partner/dashboard/transaction-data/fetch-refund/${id}/${date}`)
        .catch(() => null)
      if (resp) {
        this.itemsRefund = [...resp]
      }
      // console.log('getPartnerSalesRefund', resp)
      this.itemsRefundLoading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
